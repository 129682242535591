import React from "react";
import http from "../Utilities/httpWithoutRedirections";
import LoadingIndicator from "./LoadingIndicator";
import "./SplashPage.css";
import Logo from "../Logo";
import logoEarth from "../images/logo-earth.png";

class Splash extends React.Component {
  componentDidMount() {
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => resolve(true), 1700);
    });

    const checkRequestPromise = http.get("/auth/check");

    Promise.all([checkRequestPromise, timeoutPromise])
      .then((values) => {
        const checkResponse = values[0];
        const user = checkResponse.data.userId;
        const userType = checkResponse.data.userType;
        const fCompanyCode = checkResponse.data.fCompanyCode;
        this.props.setLoaded(user, userType, fCompanyCode);
      })
      .catch((error) => {
        this.props.setLoaded(null);
      });
  }

  render() {
    return (
      <div className="splash-center-screen">
        <div style={{ minWidth: "300px" }}>
          <div
            style={{
              width: "100%",
              marginBottom: "15px",
              borderRadius: "3px",
              padding: "15px",
              textAlign: "center",
            }}
          >
            <img alt="logo" src={logoEarth} style={{ width: "30%" }} />
          </div>

          <LoadingIndicator />
        </div>
      </div>
    );
  }
}
export default Splash;
