import React from "react";

import Wrapper from "../Common/Wrapper";
import Loading from "../Common/Loading";
import Heading from "../Common/Heading";
import http from "../../Utilities/http";
import httpBlobDownload from "../../Utilities/httpBlobDownload";
import { formatDateGr, formatDateTimeGr } from "../../Utilities/Formatters";
import { StandardSorter } from "../../Utilities/Sorters";
import { ReloadOutlined, DownloadOutlined, DownOutlined } from "@ant-design/icons";
import { Table, Menu, Dropdown, Button, Input, Empty, Tooltip, Modal, Divider, Space, DatePicker, Tag, Select, message, Popconfirm } from "antd";
import { FilePdfTwoTone, FilePdfOutlined, UploadOutlined, PlusOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import ShippingOrderImportFile from "./ShippingOrdersImportFile/ShippingOrdersImportFile";
import ShippingOrderForm from "./ShippingOrderForm/ShippingOrderForm";
import "moment/locale/el";
import moment from "moment-timezone";
import locale from "antd/es/date-picker/locale/el_GR";
import "./ShippingOrders.css";
import { mapPaymentType } from "./shippingOrdersUtils";
import ShippingOrderType from "./ShippingOrderType";

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const renderBarcodeBox = (value, record) => {
    if (!record.isparent && !record.children) return value;

    const childBarcodeBoxesJoined = record.children.map((x, i) => (
        <span key={i}>
            {x.barcodeBox}
            <br />
        </span>
    ));

    return (
        <Tooltip placement="right" title={childBarcodeBoxesJoined}>
            {record.children[0].barcodeBox}, ...
        </Tooltip>
    );
};

const columns = [
    {
        title: (
            <span>
                Ημερομηνία <br />
                Καταχώρησης
            </span>
        ),
        dataIndex: "dateCreated",
        render: (value) => formatDateTimeGr(value),
        sorter: (a, b) => StandardSorter(a.dateCreated, b.dateCreated),
    },
    {
        title: "Κατασταση",
        dataIndex: "status",
        render: (value, record) => value && <Tag color={record.status == NOT_PROCESSED_STATUS ? null : "geekblue"}>{value}</Tag>,
        sorter: (a, b) => StandardSorter(a.status, b.status),
    },
    {
        title: "Barcode Αποστολής",
        dataIndex: "barcodeVoucher",
        sorter: (a, b) => StandardSorter(a.barcodeVoucher, b.barcodeVoucher),
    },
    {
        title: "Barcode κιβωτίου",
        dataIndex: "barcodeBox",
        render: renderBarcodeBox,
        sorter: (a, b) => StandardSorter(a.barcodeBox, b.barcodeBox),
    },
    {
        title: (
            <span>
                Αριθμός <br />
                παραστατικού
            </span>
        ),
        dataIndex: "invoiceNumber",
        render: (value) => <span style={{ whiteSpace: "nowrap" }}>{value}</span>,
        sorter: (a, b) => StandardSorter(a.invoiceNumber, b.invoiceNumber),
    },
    {
        title: (
            <span>
                Ημερομηνία <br />
                παραστατικού
            </span>
        ),
        dataIndex: "docDate",
        render: (value) => formatDateGr(value),
        sorter: (a, b) => StandardSorter(a.docDate, b.docDate),
    },

    {
        title: (
            <span>
                Ημερομηνία <br />
                αποστολής
            </span>
        ),
        dataIndex: "dispatchDate",
        render: (value) => formatDateGr(value),
        sorter: (a, b) => StandardSorter(a.docDate, b.docDate),
    },
    {
        title: <span>Παραλήπτης</span>,
        dataIndex: "receiverName",
        sorter: (a, b) => StandardSorter(a.receiverName, b.receiverName),
    },
    {
        title: "Οδός",
        dataIndex: "receiverAddress",
        sorter: (a, b) => StandardSorter(a.receiverAddress, b.receiverAddress),
    },
    {
        title: "Πόλη",
        dataIndex: "receiverTown",
        sorter: (a, b) => StandardSorter(a.receiverTown, b.receiverTown),
    },
    {
        title: "Νομός",
        dataIndex: "receiverCounty",
        sorter: (a, b) => StandardSorter(a.receiverCounty, b.receiverCounty),
    },
    {
        title: "Τ.Κ.",
        dataIndex: "receiverZipcode",
        sorter: (a, b) => StandardSorter(a.receiverCounty, b.receiverCounty),
    },
    {
        title: (
            <span>
                Kινητό <br />
                παραλήπτη
            </span>
        ),
        dataIndex: "receiverMobile",
        sorter: (a, b) => StandardSorter(a.receiverMobile, b.receiverMobile),
    },
    {
        title: (
            <span>
                Τηλέφωνο <br />
                παραλήπτη
            </span>
        ),
        dataIndex: "receiverTel",
        sorter: (a, b) => StandardSorter(a.ReceiverTel, b.ReceiverTel),
    },
    {
        title: "Ποσότητα",
        dataIndex: "unit",
        sorter: (a, b) => StandardSorter(a.unit, b.unit),
    },
    {
        title: "Μονάδα μέτρησης",
        dataIndex: "measurementUnit",
        sorter: (a, b) => StandardSorter(a.measurementUnit, b.measurementUnit),
        render: (value) => value || "TEMAXIO",
    },
    {
        title: "Βάρος",
        dataIndex: "boxWeight",
        sorter: (a, b) => StandardSorter(a.boxWeight, b.boxWeight),
    },
    {
        title: "Όγκος",
        dataIndex: "boxVolume",
        sorter: (a, b) => StandardSorter(a.boxVolume, b.boxVolume),
    },
    {
        title: (
            <span>
                Ημερομηνία <br />
                παράδοσης
            </span>
        ),
        dataIndex: "deliveryDate",
        render: (value) => formatDateGr(value),
        sorter: (a, b) => StandardSorter(a.deliveryDate, b.deliveryDate),
    },
    {
        title: (
            <span>
                Τρόπος <br />
                πληρωμης
            </span>
        ),
        dataIndex: "paymentType",
        render: (value) => mapPaymentType(value),
        sorter: (a, b) => StandardSorter(a.paymentType, b.paymentType),
    },
    {
        title: (
            <span>
                Ποσό <br />
                πληρωμής
            </span>
        ),
        dataIndex: "paymentAmount",
        sorter: (a, b) => StandardSorter(a.paymentAmount, b.paymentAmount),
    },
    {
        title: (
            <span>
                Κωδικός <br />
                πληρωμής
            </span>
        ),
        dataIndex: "paymentCode",
        sorter: (a, b) => StandardSorter(a.paymentCode, b.paymentCode),
    },
    {
        title: "Σχόλια",
        dataIndex: "comments",
        sorter: (a, b) => StandardSorter(a.comments, b.comments),
    },
];

const NOT_PROCESSED_STATUS = "ΜΗ ΕΠΕΞΕΡΓΑΣΜΕΝΗ";

class App extends React.Component {
    state = {
        searchText: "",
        dateFrom: moment().subtract(1, "days").startOf("day").toISOString(true),
        dateTo: moment().endOf("day").toISOString(true),
        loading: false,
        loadingReport: false,
        loadingDelete: false,
        shippingOrders: [],
        selectedIds: [], // Check here to configure the default column
        isInsertFileModalOpen: false,
        shippingOrderType: ShippingOrderType.Order,
        isInsertFormModalOpen: false,
        deliveryStatuses: null,
        selectedDeliveryStatus: null,
        childrenIdParentIdsMap: {},
        dateIntervalType: "yesterday",
        taxOffices: [],
        measurementUnits: [],
    };

    componentDidMount() {
        this.fetchStaticData();
        this.fetchShippingOrders();
    }

    onInsertDateChange = (values) => {
        if (values == null || values.size == 0) {
            this.setState({ dateFrom: null, dateTo: null });
            return;
        }

        let from = values[0];
        let to = values[1];
        const formattedDateFrom = from.local().toISOString(true);
        const formattedDateTo = to.local().toISOString(true);

        this.setState({ dateFrom: formattedDateFrom, dateTo: formattedDateTo }, () => this.fetchShippingOrders());
    };

    onSearchTextChange = (evt) => {
        const textValue = evt.target.value;
        this.setState({ searchText: textValue }, () => (textValue == "" ? this.fetchShippingOrders() : null));
    };
    onSelectChange = (selectedIds) => {
        this.setState({ selectedIds });
    };

    onDeliveryStatusTypeChange = (value) => {
        this.setState({ selectedDeliveryStatus: value }, () => this.fetchShippingOrders());
    };

    fetchStaticData = () => {
        http.get("/taxOffice")
            .then((res) => {
                this.setState({ taxOffices: res.data });
            })
            .catch((error) => {
                this.setState({ taxOffices: [] });
            });

        http.get("/measurementunit")
            .then((res) => {
                this.setState({ measurementUnits: res.data });
            })
            .catch((error) => {
                this.setState({ measurementUnits: [] });
            });

        http.get("/shippingorder/payment-methods")
            .then((res) => {
                this.setState({ paymentMethods: res.data });
            })
            .catch((error) => {
                this.setState({ paymentMethods: [] });
            });
    };

    fetchShippingOrders = () => {
        this.setState({ loading: true });

        http.get("/deliveryStatus/types")
            .then((res) => {
                const newDeliveryStatuses = [NOT_PROCESSED_STATUS].concat(res.data);
                const status = newDeliveryStatuses.includes(this.state.selectedDeliveryStatus) ? this.state.selectedDeliveryStatus : null;
                this.setState({
                    deliveryStatuses: newDeliveryStatuses,
                    selectedDeliveryStatus: status,
                });
            })
            .catch((error) => {
                this.setState({ deliveryStatuses: null, selectedDeliveryStatus: null });
            });

        http.get("/shippingOrder", {
            params: {
                grouped: true,
                searchText: this.state.searchText,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo,
                status: this.state.selectedDeliveryStatus,
            },
        })
            .then((res) => {
                const shippingOrders = res.data.map((x) => ({ ...x, key: x.id }));
                shippingOrders.forEach((parent) => {
                    if (parent.children) {
                        parent.children.map((x) => ({ ...x, key: x.id }));
                    }
                });
                this.setState({ shippingOrders: shippingOrders, loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
            });
    };

    getExcel = () => {
        this.setState({ loading: true });
        httpBlobDownload
            .get("/shippingOrder", {
                params: {
                    excel: true,
                    searchText: this.state.searchText,
                    dateFrom: this.state.dateFrom,
                    dateTo: this.state.dateTo,
                    status: this.state.selectedDeliveryStatus,
                },
            })
            .then((response) => {
                //Create a Blob from the Excel Stream
                const file = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
            });
    };

    printingIsAvailable = () => {
        return this.state.selectedIds && this.state.selectedIds.length > 0;
    };

    getVoucher = (paperSize = "A4") => {
        this.setState({ loadingReport: true });
        httpBlobDownload
            .get("shippingOrder/voucherReport", {
                params: {
                    selectedIds: this.state.selectedIds,
                    asBlob: true,
                    paperSize: paperSize,
                },
                paramsSerializer: (params) => this.parseParams(params),
            })
            .then((response) => {
                this.setState({ loadingReport: false, selectedIds: [] });

                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], { type: "application/pdf" });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
            })
            .catch((error) => {
                this.setState({ loadingReport: false, selectedIds: [] });
            });
    };

    parseParams = (params) => {
        const keys = Object.keys(params);
        let options = "";

        keys.forEach((key) => {
            const isParamTypeObject = typeof params[key] === "object";
            const isParamTypeArray = isParamTypeObject && params[key].length >= 0;

            if (!isParamTypeObject) {
                options += `${key}=${params[key]}&`;
            }

            if (isParamTypeObject && isParamTypeArray) {
                params[key].forEach((element) => {
                    options += `${key}=${element}&`;
                });
            }
        });

        return options ? options.slice(0, -1) : options;
    };

    toggleInsertFileModal = (orderType = ShippingOrderType.Order) => {
        const modalIsOpen = this.state.isInsertFileModalOpen;
        this.setState({ isInsertFileModalOpen: !modalIsOpen, shippingOrderType: orderType });
    };

    toggleInsertFormModal = () => {
        const modalIsOpen = this.state.isInsertFormModalOpen;
        this.setState({ isInsertFormModalOpen: !modalIsOpen });
    };

    deleteIsAvailable = () => {
        const selectedIds = this.state.selectedIds ? this.state.selectedIds : [];

        if (selectedIds.length == 0) {
            return false;
        }

        const shippingOrders = this.state.shippingOrders ? this.state.shippingOrders : [];

        if (shippingOrders.length == 0) {
            return false;
        }

        const selectedShippingOrders = shippingOrders.filter((x) => selectedIds.includes(x.id));

        //if any of them is processed, then you cannot delete
        const allSelectedAreAvailableForDelete = selectedShippingOrders.every((x) => x.status == NOT_PROCESSED_STATUS);

        return allSelectedAreAvailableForDelete;
    };

    onDelete = () => {
        this.setState({ loadingDelete: true });

        http.delete("shippingOrder", {
            params: {
                selectedIds: this.state.selectedIds,
            },
            paramsSerializer: (params) => this.parseParams(params),
        })
            .then((res) => {
                this.setState({ loadingDelete: false, selectedIds: [] });
                this.fetchShippingOrders();
                message.success("Επιτυχής διαγραφή.");
            })
            .catch((error) => {
                this.setState({ loadingDelete: false, selectedIds: [] });

                if (error.response && error.response.status == 403) {
                    message.error("Κάποιες αποστολές εχουν ήδη επεξεργασθεί και δε γίνεται να διαγραφούν, προσπαθείστε ξανά.");
                } else {
                    message.error("Σφάλμα κατα την διαγραφή!");
                }
            });
    };

    onDateIntervalTypeSelection = (type) => {
        switch (type) {
            case "today":
                this.setState(
                    {
                        dateIntervalType: "today",
                        dateFrom: moment().startOf("day").toISOString(true),
                        dateTo: moment().endOf("day").toISOString(true),
                    },
                    this.fetchShippingOrders
                );
                return;
            case "yesterday":
                this.setState(
                    {
                        dateIntervalType: "yesterday",
                        dateFrom: moment().subtract(1, "days").startOf("day").toISOString(true),
                        dateTo: moment().endOf("day").toISOString(true),
                    },
                    this.fetchShippingOrders
                );
                return;
            case "week":
                this.setState(
                    {
                        dateIntervalType: "week",
                        dateFrom: moment().subtract(6, "days").startOf("day").toISOString(true),
                        dateTo: moment().endOf("day").toISOString(true),
                    },
                    this.fetchShippingOrders
                );
                return;
            case "month":
                this.setState(
                    {
                        dateIntervalType: "month",
                        dateFrom: moment().subtract(29, "days").startOf("day").toISOString(true),
                        dateTo: moment().endOf("day").toISOString(true),
                    },
                    this.fetchShippingOrders
                );
                return;
            case "year":
                this.setState(
                    {
                        dateIntervalType: "year",
                        dateFrom: moment().subtract(364, "days").startOf("day").toISOString(true),
                        dateTo: moment().endOf("day").toISOString(true),
                    },
                    this.fetchShippingOrders
                );
                break;
            case "precise":
                this.setState({ dateIntervalType: "precise" });
                break;
            default:
                this.setState({ dateIntervalType: "yesterday" });
                break;
        }
    };

    render() {
        const {
            selectedIds,
            shippingOrders,
            loading,
            loadingReport,
            isInsertFileModalOpen,
            shippingOrderType,
            isInsertFormModalOpen,
            dateFrom,
            dateTo,
            deliveryStatuses,
            loadingDelete,
            dateIntervalType,
        } = this.state;

        const menu = (
            <Menu>
                <Menu.Item key={1} onClick={() => this.getVoucher("A4")}>
                    Paper size: A4
                </Menu.Item>
                <Menu.Item key={2} onClick={() => this.getVoucher("A6")}>
                    Paper size: A6
                </Menu.Item>
            </Menu>
        );

        const printButton = (
            <Dropdown.Button
                type="primary"
                icon={<DownOutlined />}
                style={{ float: "right" }}
                size="large"
                disabled={!this.printingIsAvailable()}
                onClick={() => this.getVoucher("A4")}
                loading={loadingReport}
                overlay={menu}
            >
                Εκτύπωση voucher {this.printingIsAvailable() ? <FilePdfTwoTone twoToneColor="#D9534F" /> : <FilePdfOutlined />}
            </Dropdown.Button>
        );
        const printButtonWithTooltip = (
            <Tooltip placement="topRight" title="Επιλέξτε από τη λίστα για να εκτυπώσετε voucher">
                {printButton}
            </Tooltip>
        );

        const deleteButton = (
            <Popconfirm
                disabled={!this.deleteIsAvailable()}
                placement="top"
                title="Είστε σίγουροι για την διαγραφή των επιλεγμένων;"
                onConfirm={this.onDelete}
                okText="Ναι"
                cancelText="Όχι"
            >
                <Button danger icon={<DeleteOutlined />} style={{ marginRight: "10px" }} size="large" disabled={!this.deleteIsAvailable()} loading={loadingDelete}>
                    Διαγραφή
                </Button>
            </Popconfirm>
        );
        const deleteButtonWithTooltip = (
            <Tooltip placement="topRight" title="Επιλέξτε από τη λίστα μόνο μη επεξεργασμένες εντολές για να διαγράψετε">
                {deleteButton}
            </Tooltip>
        );

        return (
            <div>
                <Modal
                    title={ shippingOrderType === ShippingOrderType.Order
                        ? <h2 style={{ padding: "10px 0 10px 0" }}>Εισαγωγή εντολών μεταφοράς από αρχείο</h2>
                    : <h2 style={{ padding: "10px 0 10px 0" }}>Εισαγωγή εντολών περισυλλογής από αρχείο</h2>
                    }
                    centered
                    visible={isInsertFileModalOpen}
                    width="90%"
                    onCancel={this.toggleInsertFileModal}
                    footer={null}
                    destroyOnClose
                >
                    <div>
                        <ShippingOrderImportFile onSuccessfullSave={this.fetchShippingOrders} shippingOrderType={shippingOrderType} />
                    </div>
                </Modal>
                <Modal
                    width={900}
                    title={<h2 style={{ padding: "10px 0 10px 0" }}>Εισαγωγή εντολής μεταφοράς</h2>}
                    visible={isInsertFormModalOpen}
                    onCancel={this.toggleInsertFormModal}
                    footer={null}
                    destroyOnClose
                >
                    <div>
                        <ShippingOrderForm
                            taxOffices={this.state.taxOffices}
                            measurementUnits={this.state.measurementUnits}
                            paymentMethods={this.state.paymentMethods}
                            onSuccessfullSave={() => {
                                this.fetchShippingOrders();
                                this.toggleInsertFormModal();
                            }}
                        />
                    </div>
                </Modal>
                <Heading>Εντολές μεταφοράς</Heading>
                <Wrapper>
                    <Loading loading={loading}>
                        <div style={{ marginBottom: "10px" }}>
                            <Button style={{ marginRight: "15px" }} size="large" onClick={() => this.toggleInsertFormModal()}>
                                <PlusOutlined />
                                Εισαγωγή νέας εντολής
                            </Button>
                            <Button style={{ marginRight: "15px" }} size="large" onClick={() => this.toggleInsertFileModal(ShippingOrderType.Order)}>
                                <UploadOutlined />
                                Εισαγωγή εντολών μεταφοράς από αρχείο
                            </Button>
                            <Button style={{ marginRight: "15px" }} size="large" onClick={() => this.toggleInsertFileModal(ShippingOrderType.Collection)}>
                                <UploadOutlined />
                                Εισαγωγή εντολών περισυλλογής από αρχείο
                            </Button>
                            {this.deleteIsAvailable() ? deleteButton : deleteButtonWithTooltip}
                            {this.printingIsAvailable() ? printButton : printButtonWithTooltip}
                            <div style={{ clear: "both" }}></div>
                        </div>
                        <div style={{ paddingBottom: "20px" }}>
                            <Divider />
                            <Select dropdownMatchSelectWidth={false} value={dateIntervalType} onChange={this.onDateIntervalTypeSelection} style={{ margin: "0 10px 10px 0" }}>
                                <Option value="today">Σήμερα</Option>
                                <Option value="yesterday">Από εχθές</Option>
                                <Option value="week">Μια εβδομάδα πριν</Option>
                                <Option value="month">Ένας μήνας πριν</Option>
                                <Option value="year">Ένας χρόνος πριν</Option>
                                <Option value="precise">Ακριβές διάστημα</Option>
                            </Select>

                            {deliveryStatuses && deliveryStatuses.length && (
                                <Select
                                    allowClear
                                    onChange={this.onDeliveryStatusTypeChange}
                                    placeholder="Κατάσταση"
                                    style={{ marginRight: "10px" }}
                                    dropdownMatchSelectWidth={false}
                                >
                                    {deliveryStatuses.map((type) => (
                                        <Option value={type}>{type}</Option>
                                    ))}
                                </Select>
                            )}

                            <Search
                                placeholder="Αναζητήστε στις εντολές"
                                allowClear
                                onSearch={this.fetchShippingOrders}
                                onChange={this.onSearchTextChange}
                                style={{ maxWidth: 510, marginRight: "10px" }}
                                disabled={loading}
                            />
                            <Button style={{ float: "right", marginRight: "10px" }} type="round" onClick={() => this.fetchShippingOrders()} disabled={loading}>
                                <ReloadOutlined />
                                Ανανέωση
                            </Button>
                            <Button style={{ float: "right", marginRight: "10px" }} type="circle" onClick={this.getExcel} disabled={loading}>
                                <DownloadOutlined />
                            </Button>
                            <br />
                            <Space direction="vertical" size={12} style={{ marginRight: "10px" }} hidden={dateIntervalType != "precise"}>
                                <RangePicker
                                    value={[dateFrom ? moment(dateFrom) : null, dateTo ? moment(dateTo) : null]}
                                    placeholder={["Από", "Εώς"]}
                                    showTime={{
                                        format: "HH:mm",
                                        defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")],
                                    }}
                                    format={["D/M/YYYY HH:mm", "D/M/YYYY HH:mm"]}
                                    onChange={this.onInsertDateChange}
                                    disabled={loading}
                                    locale={locale}
                                    allowClear={false}
                                />
                            </Space>
                        </div>
                        <Table
                            rowClassName={(row) => !row.isParent && "expanded-shipping-order-row"}
                            columns={columns}
                            dataSource={shippingOrders}
                            scroll={{ x: "max-content" }}
                            rowKey={(record) => record.id}
                            size="small"
                            locale={{
                                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Δεν υπάρχουν δεδομένα" />,
                            }}
                            pagination={{
                                showSizeChanger: true,
                                total: shippingOrders ? shippingOrders.length : 0,
                            }}
                            rowSelection={{
                                fixed: true,
                                selectedRowKeys: selectedIds ? selectedIds : [],
                                onChange: this.onSelectChange,
                                checkStrictly: false,
                            }}
                        />
                    </Loading>
                </Wrapper>
            </div>
        );
    }
}

export default App;
