import React from 'react'
import {Card} from 'antd';

function Wrapper(props) {
    
  return (
    <Card style={{margin:"10px 0"}}> 
      {props.children}
    </Card>
  );
}

export default Wrapper;