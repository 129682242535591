import React, { useState } from "react";
import "moment/locale/el";
import moment from "moment-timezone";
import locale from "antd/es/date-picker/locale/el_GR";
import http from "../../../Utilities/http";
import Loading from "../../Common/Loading";
import {
  Form,
  Input,
  InputNumber,
  Button,
  DatePicker,
  Select,
  message,
  Checkbox,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const { Option } = Select;
const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
};
const validateMessages = {
  required: "Υποχρεωτικό πεδίο",
  types: {
    number: "Μη έγκυρος αριθμός",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

function ShippingOrdersStatus(props) {
    console.log(props)
  const { onSuccessfullSave, taxOffices, measurementUnits, paymentMethods } = props;

  const [isLoading, setLoading] = useState(false);
  const [isDifferentInvoiceReceiver, setIsDifferentInvoiceReceiver] =
    useState(false);
  const [invoiceReceiverDataChanged, setInvoiceReceiverDataChanged] =
    useState(false);
  const [invoiceTaxRegNumberHasValue, setInvoiceTaxRegNumberHasValue] =
    useState(false);
  const [form] = Form.useForm();

  const [isPaymentRequired, setIsPaymentRequired] = useState(false);

  moment.tz.setDefault("Europe/Athens");

  const onFinish = (values) => {
    setLoading(true);
    http
      .post("/ShippingOrder", values)
      .then((result) => {
        setLoading(false);
        message.success("Επιτυχής αποθήκευση");
        onSuccessfullSave();
      })
      .catch((error) => {
        message.error("Σφάλμα αποθήκευσης");
        setLoading(false);
      });
  };

  const invoiceReceiverTextInput = (
    <Input
      style={{
        color: invoiceReceiverDataChanged ? "black" : "grey",
        fontStyle: invoiceReceiverDataChanged ? "normal" : "italic",
      }}
      onChange={() => setInvoiceReceiverDataChanged(true)}
    />
  );

  const measurementUnitFormItem = (
    <Form.Item
      name="measurementUnit"
      label="Μονάδα μέτρησης"
      rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
      initialValue="000001"
      noStyle
      suffixIcon={"hello"}
    >
      <Select
        showSearch="true"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
        dropdownMatchSelectWidth={false}
      >
        {measurementUnits &&
          measurementUnits.map((_) => (
            <Option value={_.code}>{_.description}</Option>
          ))}
      </Select>
    </Form.Item>
  );

  return (
    <Loading loading={isLoading}>
      <Form
        {...layout}
        onFinish={onFinish}
        validateMessages={validateMessages}
        name="shipping-orders-form"
        form={form}
      >
        <Form.Item
          name="invoiceNumber"
          label="Αριθμός παραστατικού"
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="docDate"
          label="Ημερομηνία παραστατικού"
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
        >
          <DatePicker
            locale={{ locale }}
            format="D/M/YYYY"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          name="dispatchDate"
          label="Ημερομηνία Αποστολής"
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
        >
          <DatePicker
            locale={{ locale }}
            format="D/M/YYYY"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          name="receiverName"
          label="Παραλήπτης"
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
        >
          <Input
            onChange={(e) => {
              if (!invoiceReceiverDataChanged) {
                form.setFieldsValue({
                  receiverInvoiceName: e.target.value,
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="receiverAddress"
          label="Οδός"
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
        >
          <Input
            onChange={(e) => {
              if (!invoiceReceiverDataChanged) {
                form.setFieldsValue({
                  receiverInvoiceAddress: e.target.value,
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="receiverTown"
          label="Πόλη"
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
        >
          <Input
            onChange={(e) => {
              if (!invoiceReceiverDataChanged) {
                form.setFieldsValue({
                  receiverInvoiceTown: e.target.value,
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="receiverCounty"
          label="Νομός"
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
        >
          <Input
            onChange={(e) => {
              if (!invoiceReceiverDataChanged) {
                form.setFieldsValue({
                  receiverInvoiceCounty: e.target.value,
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="receiverZipcode"
          label="Τ.Κ."
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
        >
          <Input
            onChange={(e) => {
              if (!invoiceReceiverDataChanged) {
                form.setFieldsValue({
                  receiverInvoiceZipcode: e.target.value,
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="receiverTel"
          label="Τηλέφωνο παραλήπτη"
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
        >
          <Input
            onChange={(e) => {
              if (!invoiceReceiverDataChanged) {
                form.setFieldsValue({
                  receiverInvoiceTel: e.target.value,
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="receiverMobile"
          label="Κινητό παραλήπτη"
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
        >
          <Input
            onChange={(e) => {
              if (!invoiceReceiverDataChanged) {
                form.setFieldsValue({
                  receiverInvoiceMobile: e.target.value,
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="unit"
          label="Ποσότητα"
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
        >
          <InputNumber precision={0} min={1} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="measurementUnit"
          label="Μονάδα μέτρησης"
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
          initialValue="000001"
        >
          <Select
            showSearch="true"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            dropdownMatchSelectWidth={false}
          >
            {measurementUnits &&
              measurementUnits.map((_) => (
                <Option value={_.code}>{_.description}</Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="boxWeight"
          label="Βάρος"
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
        >
          <InputNumber
            style={{ width: "100%" }}
            decimalSeparator=","
            min={0.001}
          />
        </Form.Item>
        <Form.Item
          name="boxVolume"
          label="Όγκος"
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
        >
          <InputNumber
            style={{ width: "100%" }}
            decimalSeparator=","
            min={0.001}
          />
        </Form.Item>
        <Form.List name="extraUnits">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <>
                  <Form.Item
                    {...field}
                    label={"Ποσότητα " + (index + 2)}
                    name={[field.name, "unit"]}
                    fieldKey={[field.fieldKey, "unit"]}
                    rules={[
                      {
                        required: true,
                        message:
                          "Υποχρεωτικό πεδίο, αλλιώς αφαιρέστε το  " +
                          (index + 2) +
                          "ο φορτίο",
                      },
                    ]}
                  >
                    <InputNumber
                      precision={0}
                      min={1}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label={"Μονάδα μέτρησης " + (index + 2)}
                    name={[field.name, "measurementUnit"]}
                    fieldKey={[field.fieldKey, "measurementUnit"]}
                    rules={[
                      {
                        required: true,
                        message:
                          "Υποχρεωτικό πεδίο, αλλιώς αφαιρέστε το  " +
                          (index + 2) +
                          "ο φορτίο",
                      },
                    ]}
                    initialValue="000001"
                  >
                    <Select
                      showSearch="true"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      dropdownMatchSelectWidth={false}
                    >
                      {measurementUnits &&
                        measurementUnits.map((_) => (
                          <Option value={_.code} key={_.code}>
                            {_.description}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label={"Βάρος " + (index + 2)}
                    name={[field.name, "boxWeight"]}
                    fieldKey={[field.fieldKey, "boxWeight"]}
                    rules={[
                      {
                        required: true,
                        message:
                          "Υποχρεωτικό πεδίο, αλλιώς αφαιρέστε το  " +
                          (index + 2) +
                          "ο φορτίο",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      decimalSeparator=","
                      min={0.001}
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label={"Όγκος " + (index + 2)}
                    name={[field.name, "boxVolume"]}
                    fieldKey={[field.fieldKey, "boxVolume"]}
                    rules={[
                      {
                        required: true,
                        message:
                          "Υποχρεωτικό πεδίο, αλλιώς αφαιρέστε το  " +
                          (index + 2) +
                          "ο φορτίο",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      decimalSeparator=","
                      min={0.001}
                    />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 7, span: 17 }} fieldKey={[field.key, "removeButton"]}>
                    <Button
                      block
                      key={field.key}
                      title={"Αφαίρεση " + (index + 2) + "ου φορτίου"}
                      onClick={() => remove(index)}
                    >
                      <MinusOutlined style={{ color: "grey" }} />
                      {"Αφαίρεση φορτίου " + (index + 2)}
                    </Button>
                  </Form.Item>
                </>
              ))}
              <Form.Item wrapperCol={{ offset: 7, span: 17 }}>
                <Button
                  block
                  type="dashed"
                  title="Προσθήκη επιπλέον φορτίου"
                  onClick={() =>
                    add({
                      unit: null,
                      measurementUnit: "000001",
                      boxWeight: null,
                      boxVolume: null,
                    })
                  }
                >
                  <PlusOutlined style={{ color: "grey" }} />
                  Προσθήκη επιπλέον φορτίου
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item
          name="deliveryDate"
          label="Ημερομηνία Παράδοσης"
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
        >
          <DatePicker
            locale={{ locale }}
            format="D/M/YYYY"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          hidden
          name="deliverySlot"
          label="Χρονοθυρίδα παράδοσης"
          initialValue={0}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="paymentType"
          label="Τρόπος πληρωμής εμπορευμάτων"
          rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
        >
          <Select onChange={(e) => setIsPaymentRequired(e == 0)} >
            <Option value={0}>Αντικαταβολή</Option>
            <Option value={1}>Τράπεζα</Option>
            <Option value={2}>Επιταγή</Option>
            <Option value={3}>Μετρητά</Option>
            <Option value={4}>Paypal</Option>
            <Option value={5}>Πιστωτική</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="paymentAmount"
          label="Ποσό πληρωμής εμπορευμάτων"
          rules={[{ required: isPaymentRequired, message: "Υποχρεωτικό πεδίο" }]}
        >
          <InputNumber style={{ width: "100%" }} decimalSeparator="," />
        </Form.Item>
        <Form.Item name="paymentCode" label="Κωδικός πληρωμής">
          <Input />
        </Form.Item>
        <Form.Item name="isDifferentInvoiceReceiver" label="Χρέωση παραλήπτη">
          <Checkbox
            onChange={(e) => setIsDifferentInvoiceReceiver(e.target.checked)}
          />
        </Form.Item>
        {isDifferentInvoiceReceiver && (
          <>
            <Form.Item
              name="receiverInvoiceName"
              label="Παραλήπτης τιμολόγησης"
              rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
            >
              {invoiceReceiverTextInput}
            </Form.Item>
            <Form.Item
              name="receiverInvoiceAddress"
              label="Οδός παραλήπτη τιμολόγησης"
              rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
            >
              {invoiceReceiverTextInput}
            </Form.Item>
            <Form.Item
              name="receiverInvoiceTown"
              label="Πόλη παραλήπτη τιμολόγησης"
              rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
            >
              {invoiceReceiverTextInput}
            </Form.Item>
            <Form.Item
              name="receiverInvoiceCounty"
              label="Νομός παραλήπτη τιμολόγησης"
              rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
            >
              {invoiceReceiverTextInput}
            </Form.Item>
            <Form.Item
              name="receiverInvoiceZipcode"
              label="Τ.Κ. παραλήπτη τιμολόγησης"
              rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
            >
              {invoiceReceiverTextInput}
            </Form.Item>
            <Form.Item
              name="receiverInvoiceTel"
              label="Τηλέφωνο παραλήπτη τιμολόγησης"
              rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
            >
              {invoiceReceiverTextInput}
            </Form.Item>
            <Form.Item
              name="receiverInvoiceMobile"
              label="Κινητό παραλήπτη τιμολόγησης"
              rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
            >
              {invoiceReceiverTextInput}
            </Form.Item>
            <Form.Item
              name="receiverInvoicePaymentType"
              label="Τρόπος πληρωμής μεταφοράς"
              rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
            >
              <Select>
                {paymentMethods && paymentMethods.map((_) => (<Option value={_.description}>{_.description}</Option>))}
              </Select>
            </Form.Item>
            <Form.Item
              name="receiverInvoicePaymentAmount"
              label="Ποσό πληρωμής υπηρεσίας"
              rules={[{ required: true, message: "Υποχρεωτικό πεδίο" }]}
            >
              <InputNumber style={{ width: "100%" }} decimalSeparator="," />
            </Form.Item>
            <Form.Item
              name="receiverInvoiceTaxRegistrationNumber"
              label="ΑΦΜ παραλήπτη τιμολόγησης"
            >
              <Input
                onChange={(e) =>
                  setInvoiceTaxRegNumberHasValue(!!e.target.value)
                }
              />
            </Form.Item>
            <Form.Item
              name="receiverInvoiceTaxOffice"
              label="ΔΟΥ παραλήπτη τιμολόγησης"
              rules={[
                {
                  required: invoiceTaxRegNumberHasValue,
                  message: "Υποχρεωτικό πεδίο εφόσον εισάγατε ΑΦΜ",
                },
              ]}
            >
              <Select
                showSearch="true"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {taxOffices &&
                  taxOffices.map((x) => (
                    <Option value={x.code}>{x.description}</Option>
                  ))}
              </Select>
            </Form.Item>
          </>
        )}
        <Form.Item name="comments" label="Σχόλια">
          <Input.TextArea />
        </Form.Item>
        <div style={{ padding: "10px" }}>
          <Button type="primary" htmlType="submit" style={{ float: "right" }}>
            Αποθήκευση
          </Button>
          <div style={{ clear: "both" }}></div>
        </div>
      </Form>
    </Loading>
  );
}

export default ShippingOrdersStatus;
