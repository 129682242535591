import React, { useState, useEffect } from 'react';
import ProcessFile from './ProcessFile/ProcessFile'
import ShowProcessResults from './ShowProcessResults/ShowProcessResults'
import httpFileUpload from '../../../Utilities/httpFileUpload'
import httpBlobDownload from '../../../Utilities/httpBlobDownload'
import ShowSaveResults from './ShowSaveResults/ShowSaveResults'
import Loading from '../../Common/Loading'
import http from '../../../Utilities/http'

import { Steps } from 'antd'
import { FileAddOutlined, FileDoneOutlined, UploadOutlined } from '@ant-design/icons';
import ShippingOrderType from '../ShippingOrderType';

const { Step } = Steps;

const ShippingOrderImportFile = (props) => {
    console.log(props)
  let mounted = true;
  const { onSuccessfullSave, shippingOrderType } = props
  const [loading, setLoading] = useState(false);

  const [fileIsProcessed, setFileIsProcessed] = useState(false);
  const [processingResults, setProcessingResults] = useState(null);
  const [fileIsSaved, setFileIsSaved] = useState(false);
  const [savedItems, setSaveditems] = useState(null)

  useEffect(() => {
    mounted = true;
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const uploadFile = (file) => {
    setLoading(true);

    const data = new FormData()
    data.append('file', file)


    httpFileUpload.post('/ShippingOrder/processFile', data)
    .then((result) => {
        console.log(result.data);
        const alteredData = result.data = result.data.map(obj => ({ ...obj, orderType: shippingOrderType }));
        console.log(alteredData);
        if (mounted) {
          setProcessingResults(alteredData)
          setFileIsProcessed(true)
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error)
        if (mounted) {
          setProcessingResults(null)
          setFileIsProcessed(true)
          setLoading(false);
        }
      })
  }

  const saveRecords = () => {
    setLoading(true);
    const data = processingResults;

    http.post('/ShippingOrder/multiple', data)
      .then((result) => {
        if (mounted) {
          setSaveditems(result.data)
          setFileIsSaved(true)
          setLoading(false);
          onSuccessfullSave();
        }
      })
      .catch((error) => {
        if (mounted) {
          setFileIsSaved(true)
          setSaveditems(null); //saving has happened but saved results are null
          setLoading(false);
        }
      })
  }

  const getCurrentStep = () => {
    if (!fileIsProcessed) {
      return 0;
    }
    else if (!fileIsSaved) {
      return 1;
    }
    else {
      return 2;
    }
  }

  const reset = () => {
    if (mounted) {
      setFileIsProcessed(false);
      setProcessingResults(null);
      setFileIsSaved(false);
      setSaveditems(null);
    }
  }

  const getVoucher = () => {
    setLoading(true);
    const idList = savedItems ? savedItems.map(x => x.id) : []
    httpBlobDownload.get('shippingOrder/voucherReport',
      {
        'params': {
          selectedIds: idList,
          asBlob: true
        },
        'paramsSerializer': params => parseParams(params)
      })
      .then(response => {
        if (mounted) {
          setLoading(false);

          //Create a Blob from the PDF Stream
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
        }
      })
      .catch(error => {
        if (mounted) {
          setLoading(false);
        }
      });
  }

  const parseParams = (params) => {
    const keys = Object.keys(params);
    let options = '';

    keys.forEach((key) => {
      const isParamTypeObject = typeof params[key] === 'object';
      const isParamTypeArray = isParamTypeObject && (params[key].length >= 0);

      if (!isParamTypeObject) {
        options += `${key}=${params[key]}&`;
      }

      if (isParamTypeObject && isParamTypeArray) {
        params[key].forEach((element) => {
          options += `${key}=${element}&`;
        });
      }
    });

    return options ? options.slice(0, -1) : options;
  };

  return (
      <div>
        <Steps current={getCurrentStep()}>
          <Step title="Εισαγωγή αρχείου" />
          <Step title="Προεπισκόπιση" />
          <Step title="Αποθήκευση" />
        </Steps>
        <br />
        <Loading loading={loading}>
        {getCurrentStep() == 0 && <ProcessFile uploadFile={uploadFile} loading={loading} />}
        {getCurrentStep() == 1 && <ShowProcessResults
          processingResults={processingResults}
          saveRecords={saveRecords}
          reset={reset}
        />}
        {getCurrentStep() == 2 && <ShowSaveResults
          savedItems={savedItems}
          getVoucher={getVoucher}
          reset={reset}
        />}
        </Loading>
      </div>
  );
}

export default ShippingOrderImportFile;
