import React, { useContext } from "react";
import { Layout, Menu, Dropdown, Button } from "antd";
import "./MainApp.css";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import ShippingOrderForm from "./ShippingOrders/ShippingOrderForm/ShippingOrderForm";
import ShippingOrdersImportFile from "./ShippingOrders/ShippingOrdersImportFile/ShippingOrdersImportFile";
import ShippingOrdersView from "./ShippingOrders/ShippingOrders";
import Admin from "./Admin/Admin";
import UserContext from "../UserContext";
import Logo from "../Logo";

import UserMenu from "./Common/UserMenu";

import Usertypes from "./Common/UserTypes";

const { Header, Footer, Sider, Content } = Layout;

function MainApp(props) {
  const context = useContext(UserContext);

  const logout = (e) => {
    if (e) {
      e.preventDefault();
    }
    context.setCurrentUser(null);
  };

  const redirectToUpdatePassword = (e) => {
    if (e) {
      e.preventDefault();
    }
    props.history.push("/update-password");
  };

  return (
    <div>
      {!context.currentUser ? <Redirect to="/login" /> : <Redirect to="/app" />}
      <Layout className="layout">
        <Header
          className="header"
          style={{ borderBottom: "3px solid lightgrey", height: "80px" }}
        >
          <Logo imgStyle={{ height: "75px", padding: "5px" }} />
          <UserMenu
            redirectToUpdatePassword={redirectToUpdatePassword}
            logout={logout}
          />
        </Header>
        <Content className="content">
          <div className="content-body">
            <Switch>
              <Route
                key=""
                path="/app/shipping-orders/form"
                component={ShippingOrderForm}
              />
              <Route
                path="/app/shipping-orders/file-import"
                component={ShippingOrdersImportFile}
              />
              <Route
                path="/app/shipping-orders/view"
                component={ShippingOrdersView}
              />
              <Route
                path="/app/shipping-orders"
                component={ShippingOrdersView}
              />
              <Route path="/app/admin" component={Admin} />
              <Route path="/app">
                {context.userType == Usertypes.Admin ? (
                  <Redirect push to="/app/admin" />
                ) : (
                  <Redirect push to="/app/shipping-orders" />
                )}
              </Route>
            </Switch>
          </div>
        </Content>

        <Footer
          style={{
            textAlign: "right",
            backgroundColor: "gainsboro",
            borderTop: "3px solid lightgray",
          }}
        >
          Delatolas Courier 2021 &#169;
        </Footer>
      </Layout>
    </div>
  );
}

export default withRouter(MainApp);
