import React, { useContext } from "react";
import { Dropdown, Menu } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  LockOutlined,
  DownOutlined,
} from "@ant-design/icons";
import UserContext from "..//../UserContext";

function UserMenu(props) {
  const { redirectToUpdatePassword, logout } = props;

  const context = useContext(UserContext);

  const menu = (
    <Menu style={{ padding: "10px 0" }}>
      <Menu.Item style={{ margin: "5px" }}>
        <a href="#" onClick={redirectToUpdatePassword}>
          <LockOutlined />
          Αλλαγή κωδικού πρόσβασης
        </a>
      </Menu.Item>
      <Menu.Item style={{ margin: "5px" }}>
        <a href="#" onClick={logout}>
          <LogoutOutlined />
          Αποσύνδεση
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <span style={{ float: "right" }}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        arrow
        trigger={["click"]}
      >
        <span style={{ color: "white", cursor: "pointer", fontSize: "14px" }}>
          <UserOutlined /> {context.currentUser} <DownOutlined />
        </span>
      </Dropdown>
    </span>
  );
}

export default UserMenu;
