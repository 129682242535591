import React from 'react'
import {Card} from 'antd';

function Heading(props) {
    
  return (
    <Card style={{marginBottom:"10px"}}> 
    <h1 style={{fontSize:"25px"}}>{props.children}</h1>
    </Card>
  );
}

export default Heading;