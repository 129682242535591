import React from "react";

import ManageShippingOrderExcelConfig from "./ManageShippingOrderExcelConfig/ManageShippingOrderExcelConfig";
import { Layout, Menu, Breadcrumb, Button } from "antd";
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FileExcelTwoTone,
  FileExcelOutlined,
} from "@ant-design/icons";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class ManageExcelConfig extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  render() {
    const { collapsed } = this.state;

    return (
      <Layout style={{ padding: "15px", backgroundColor: "#fff" }}>
        {/* <Menu theme="light" mode="horizontal" defaultSelectedKeys={["1"]}>
          <Menu.Item
            key="1"
            icon={<FileExcelOutlined twoToneColor="#009900" />}
          >
            Excel Εντολών μεταφοράς
          </Menu.Item>
        </Menu>
        <br /> */}
        <ManageShippingOrderExcelConfig />
      </Layout>
    );
  }
}

export default ManageExcelConfig;
