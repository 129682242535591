import axios from 'axios'
import { getToken } from './storage'
import {history} from '../index'
import { notification} from 'antd'


const instance = axios.create();

instance.defaults.baseURL = process.env.REACT_APP_BASE_URL// the prefix of the URL
instance.defaults.headers.get['Accept'] = 'application/json'   // default header for all get request
instance.defaults.headers.post['Accept'] = 'application/json'

instance.interceptors.request.use(function (config) {
  let token = getToken();
  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }
  return config;
});

instance.interceptors.response.use(
  //success
  (response) => {
    return response
  },
  //error
  function (error) {
    if (error.response && error.response.status === 401) {
      // notification.error({
      //   message: 'Σφάλμα σύνδεσης',
      //   description:
      //   'Πρέπει να συνδεθείτε για να συνεχίσετε',
      //   placement:'bottomRight'
      // });

      history.push('/login')
    }
    return Promise.reject(error);
  });

export default instance;