import React from "react";
import "./UpdatePassword.css";
import UpdatePasswordForm from "./UpdatePasswordForm/UpdatePasswordForm";
import { setToken } from "../Utilities/storage";
import http from "../Utilities/http";
import { withRouter, Route } from "react-router-dom";
import { Card } from "antd";
import Logo from "../Logo";

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: null,
    };
    this.history = props.history;
  }

  updatePassword = (values) => {
    this.setState({ loading: true });
    console.log("update password values", values);
    let credentials = {
      username: values.username,
      password: values.password,
      newPassword: values.newPassword,
    };

    http
      .post("/auth/UpdatePassword", credentials)
      .then((res) => {
        this.setState({ loading: false });
        this.history.push("/");
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (!error.response) {
          this.setState({ errorMessage: "Αδυναμία σύνδεσης με server" });
          return;
        }

        if (error.response && error.response.status == 401) {
          this.setState({
            errorMessage: "Λάθος κωδικός πρόσβασης ή όνομα χρήστη",
          });
        } else {
          this.setState({ errorMessage: "Συνέβει ένα σφάλμα" });
        }
      });
  };

  clearErrorMessage = () => {
    if (this.state.errorMessage) {
      this.setState({ errorMessage: null });
    }
  };

  render() {
    const { errorMessage, loading } = this.state;

    return (
      <div className="update-password-page">
        <div className="update-password-column">
          <div className="update-password-container">
            <div className="login-container">
              <Route path="/update-password/first">
                <Card>
                  Πρέπει να αλλάξετε τον προσωρινό κωδικό πριν συνδεθείτε στην
                  εφαρμογή
                </Card>
              </Route>
              <UpdatePasswordForm
                onFinish={this.updatePassword}
                errorMessage={errorMessage}
                clearErrorMessage={this.clearErrorMessage}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//Login.contextType = UserContext;

export default withRouter(UpdatePassword);
