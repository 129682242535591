import React, { useContext } from "react";
import logoImage_001 from "./images/logo_001.png";
import logoImage_002 from "./images/logo_002.png";
import logoImage_003 from "./images/logo_003.png";
import UserContext from "./UserContext";

function Logo({ imgStyle }) {
  const context = useContext(UserContext);

  const fCompanyCode = context.fCompanyCode;

  let logoImage = "";
  switch (fCompanyCode) {
    case "001":
      logoImage = logoImage_001;
      break;
    case "002":
      logoImage = logoImage_002;
      break;
    case "003":
      logoImage = logoImage_003;
      break;
    default:
      logoImage = logoImage_001;
  }

  return (
    <React.Fragment>
      <img alt="logo" src={logoImage} style={imgStyle} />
    </React.Fragment>
  );
}

export default Logo;
