import React from "react";
import "./App.css";
import { withRouter, Switch, Route } from "react-router-dom";
import MainApp from "./MainApp/MainApp";
import Login from "./Login/Login";
import UserContext from "./UserContext";
import UpdatePassword from "./UpdatePassword/UpdatePassword";
import SplashPage from "./SplashPage/SplashPage";
import { setFCompanyCode, getFCompanyCode } from "./Utilities/storage";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      userType: null,
      fCompanyCode: "001",
      loaded: false,
    };
  }

  componentDidMount() {
    const fCompanyCode = getFCompanyCode();
    this.setState({
      fCompanyCode: fCompanyCode,
    });
  }

  setCurrentUser = (user, userType, fCompanyCode) => {
    console.log("setting current user", user, userType, fCompanyCode);
    const previousFCompanyCode = this.state.fCompanyCode;

    this.setState({
      currentUser: user,
      userType: userType,
      fCompanyCode: fCompanyCode || previousFCompanyCode,
    });

    if (fCompanyCode) {
      setFCompanyCode(fCompanyCode);
    }
  };

  setLoaded = (userId, userType, fCompanyCode) => {
    console.log("setting loaded", userId, userType, fCompanyCode);

    const previousFCompanyCode = this.state.fCompanyCode;

    this.setState({
      currentUser: userId,
      userType: userType,
      fCompanyCode: fCompanyCode || previousFCompanyCode,
      loaded: true,
    });

    if (fCompanyCode) {
      setFCompanyCode(fCompanyCode);
    }
  };

  render() {
    const { currentUser, loaded, userType, fCompanyCode } = this.state;

    return (
      <UserContext.Provider
        value={{
          currentUser: currentUser,
          userType: userType,
          fCompanyCode: fCompanyCode,
          setCurrentUser: this.setCurrentUser,
        }}
      >
        {loaded ? (
          <Switch>
            <Route key="/login" path="/login" component={Login} />
            <Route
              key="/update-password"
              path="/update-password"
              component={UpdatePassword}
            />
            <Route key="/" path="/" component={MainApp} />
          </Switch>
        ) : (
          <SplashPage setLoaded={this.setLoaded} />
        )}
      </UserContext.Provider>
    );
  }
}

export default withRouter(App);
