const formatDateGr = (date) => {
    let dateToProcess = date;
    if(date && typeof date === "string"){
        dateToProcess = new Date(date)
    }
    if(dateToProcess && dateToProcess instanceof Date){
        return dateToProcess.toLocaleDateString("el-GR")
    }
    return ""
}

const formatDateTimeGr = (date) => {
    let dateToProcess = date;
    if(date && typeof date === "string"){
        dateToProcess = new Date(date)
    }
    if(dateToProcess && dateToProcess instanceof Date){
        return dateToProcess.toLocaleDateString("el-GR") +" "+dateToProcess.toLocaleTimeString("en-GB", { hour12: false, hour: '2-digit', minute: '2-digit' })
    }
    return ""
}


export { formatDateGr, formatDateTimeGr }