import React from 'react';
import { Form, Input, Button, Checkbox, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const LoginForm = (props) => {
    const { onFinish, errorMessage, clearErrorMessage, loading } = props;
    return (
        <Card title={<h3 style={{textAlign:"center"}}>Αλλαγή κωδικού πρόσβασης</h3>} bordered={true}>
            <Form
                name="update-password"
                className="update-password-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onChange={clearErrorMessage}
            >
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Εισάγετε όνομα χρήστη' }]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Όνομα χρήστη" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Εισάγετε κωδικό πρόσβασης' }]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Κωδικός πρόσβασης"
                    />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    rules={[{ required: true, message: 'Εισάγετε νέο κωδικό πρόσβασης' }]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Νέος κωδικός πρόσβασης"
                    />
                </Form.Item>
                <Form.Item
                    name="newPasswordConfirm"
                    dependencies={['newPassword']}
                    rules={[
                        { required: true, message: 'Εισάγετε επιβεβαίωση νέου κωδικού πρόσβασης' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Η επιβεβαίωση νέου κωδικού πρόσβασης δεν είναι ίδια με τον νέο κωδικό πρόσβασης  '));
                            },
                          })
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Επιβεβαίωση νέου κωδικού πρόσβασης"
                    />
                </Form.Item>
                <br/>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="update-password-form-button" loading={loading}>
                        Αλλαγή κωδικού
            </Button>
                </Form.Item>
                {errorMessage&&<div className="ant-form-item-explain ant-form-item-explain-error"> {errorMessage}</div>}
            </Form>
        </Card>
    )
};

export default LoginForm;






