import React from "react";
import {
  Table,
  Button,
  Input,
  Empty,
  Tooltip,
  Modal,
  Divider,
  Space,
  DatePicker,
  Tag,
  Select,
  message,
  Popconfirm,
  InputNumber,
  Card,
  Checkbox,
  Form,
  Row,
  Col,
} from "antd";

const getColumnPrettyName = (colName) => {
  switch (colName) {
    case "Doc_date":
      return "Ημερομηνία παραστατικού";
    case "Invoice_Number":
      return "Αριθμός παραστατικού";
    case "Dispatch_date":
      return "Ημερομηνία αποστολής";
    case "Measurement_unit":
      return "Μονάδα μέτρησης";
    case "Unit":
      return "Ποσότητα";
    case "Box_weight":
      return "Βάρος";
    case "Box_Volume":
      return "Όγκος";
    case "Receiver_name":
      return "Παραλήπτης";
    case "Receiver_mobile":
      return "Κινητό παραλήπτη";
    case "Receiver_tel":
      return "Τηλέφωνο παραλήπτη";
    case "Receiver_zipcode":
      return "Ταχ. Κώδικας";
    case "Receiver_address":
      return "Διεύθυνση";
    case "Receiver_town":
      return "Πόλη";
    case "Receiver_county":
      return "Νομός";
    case "Delivery_date":
      return "Ημερομηνία παράδοσης";
    case "Payment_type":
      return "Τρόπος πληρωμης";
    case "Payment_amount":
      return "Ποσό πληρωμής";
    case "Payment_code":
      return "Κωδικός πληρωμής";
    case "Comments":
      return "Σχόλια";
    default:
      return null;
  }
};

const ExcelConfigItem = (props) => {
  const {
    changeColumn,
    column,
    defaultDateFormat,
    defaultNumberDecimalPoint,
    checkRegex,
  } = props;

  console.log(column.columnName + " rendered!!");
  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
      <Card style={{ boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}>
        <h4 style={{ fontWeight: 800, textDecoration: "underline" }}>
          {getColumnPrettyName(column.columnName)}
        </h4>
        <i>{column.columnName}</i>
        <br />
        <br />
        <Form.Item label="Στήλη">
          <InputNumber
            style={{ width: "100%" }}
            value={column.columnNumber}
            onChange={(value) =>
              changeColumn(column.columnName, "columnNumber", value)
            }
          />
        </Form.Item>
        <Form.Item label="Μετατροπή σε αριθμό">
          <Checkbox
            checked={column.parseToNumber}
            onChange={(e) =>
              changeColumn(column.columnName, "parseToNumber", e.target.checked)
            }
          />
          <Input
            disabled={!column.parseToNumber}
            title="Χαρακτήρας δεκαδικών ψηφίων"
            placeholder="Χαρακτήρας δεκαδικών ψηφίων"
            style={{ maxWidth: "250px", float: "right" }}
            value={column.numberDecimalPoint}
            onChange={(e) =>
              changeColumn(
                column.columnName,
                "numberDecimalPoint",
                e.target.value
              )
            }
            onBlur={(e) =>
              !column.numberDecimalPoint &&
              changeColumn(
                column.columnName,
                "numberDecimalPoint",
                defaultNumberDecimalPoint
              )
            }
          />
        </Form.Item>
        <Form.Item label="Μετατροπή σε ημ/νία">
          <Checkbox
            checked={column.parseToDate}
            onChange={(e) =>
              changeColumn(column.columnName, "parseToDate", e.target.checked)
            }
          />
          <Input
            disabled={!column.parseToDate}
            title="Format ημερομηνίας"
            placeholder="Format ημερομηνίας"
            style={{ maxWidth: "250px", float: "right" }}
            value={column.dateFormat}
            onBlur={(e) =>
              !column.dateFormat &&
              changeColumn(column.columnName, "dateFormat", defaultDateFormat)
            }
            onChange={(e) =>
              changeColumn(column.columnName, "dateFormat", e.target.value)
            }
          />
        </Form.Item>
        <Form.Item label="Κανόνες αντικατάστασης">
          <Button
            shape="round"
            style={{ float: "right" }}
            onClick={() => {
              const newTable = column.regexReplacePairs
                ? [
                    ...column.regexReplacePairs,
                    { regex: "/κείμενο/", replacement: "νέο κείμενο" },
                  ]
                : [{ regex: "/κείμενο/", replacement: "νέο κείμενο" }];

              changeColumn(column.columnName, "regexReplacePairs", newTable);
            }}
          >
            + Προσθήκη κανόνα
          </Button>
        </Form.Item>
        {column.regexReplacePairs &&
          column.regexReplacePairs.map((regexPair, regexIndex) => (
            <Row>
              <Col span={11}>
                <Form.Item
                  style={{ width: "100%" }}
                  labelCol={0}
                  wrapperCol={24}
                >
                  <Input
                    style={{ width: "100%" }}
                    title="Regex κανόνας"
                    placeholder="Regex κανόνας"
                    value={regexPair.key}
                    onChange={(e) => {
                      const newRegexReplacePairs = [
                        ...column.regexReplacePairs,
                      ];
                      newRegexReplacePairs.splice(regexIndex, 1, {
                        ...regexPair,
                        regex: e.target.value,
                      });
                      changeColumn(
                        column.columnName,
                        "regexReplacePairs",
                        newRegexReplacePairs
                      );
                    }}
                    onBlur={(e) => checkRegex(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <div
                  style={{
                    fontSize: "17px",
                    color: "grey",
                    width: "100%",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  &#8594;
                </div>
              </Col>
              <Col span={10}>
                <Form.Item
                  style={{ width: "100%" }}
                  labelCol={0}
                  wrapperCol={24}
                >
                  <Input
                    title="Κείμενο αντικατάστασης"
                    placeholder="Κείμενο αντικατάστασης"
                    value={regexPair.value}
                    onChange={(e) => {
                      const newRegexReplacePairs = [
                        ...column.regexReplacePairs,
                      ];
                      newRegexReplacePairs.splice(regexIndex, 1, {
                        ...regexPair,
                        replacement: e.target.value,
                      });
                      changeColumn(
                        column.columnName,
                        "regexReplacePairs",
                        newRegexReplacePairs
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Button
                  shape="round"
                  style={{ marginLeft: "5px", width: "100%" }}
                  title="Αφαίρεση κανόνα"
                  onClick={(e) => {
                    const newRegexReplaceArray = [...column.regexReplacePairs];
                    newRegexReplaceArray.splice(regexIndex, 1);
                    changeColumn(
                      column.columnName,
                      "regexReplacePairs",
                      newRegexReplaceArray
                    );
                  }}
                >
                  {" "}
                  -{" "}
                </Button>
              </Col>
            </Row>
          ))}
      </Card>
    </Col>
  );
};

// function areEqual(prevProps, nextProps) {
//    if(prevProps.changeColumn != nextProps.changeColumn){
//     return false
//    }
//    if(prevProps.column == null || nextProps.column ==null){
//     return false
//    }
//    if(prevProps.column.ColumnNumber != nextProps.column.ColumnNumber
//     ||prevProps.column.ParseToNumber != nextProps.column.ParseToNumber
//     ||prevProps.column.ParseToNumberNumberDecimalPoint != nextProps.column.ParseToNumberNumberDecimalPoint
//     ||prevProps.column.ParseToDate != nextProps.column.ParseToDate
//     ||prevProps.column.DateFormat != nextProps.column.DateFormat
//     ||prevProps.column.RegexReplaceArray != nextProps.column.RegexReplaceArray){
//     return false
//    }
// return true
//   }

export default React.memo(ExcelConfigItem);
