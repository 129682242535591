import React from "react";

import Wrapper from "../../Common/Heading";
import Heading from "../../Common/Heading";
import http from "../../../Utilities/http";
import Loading from "../../Common/Loading";

import {
  Button,
  Input,
  Select,
  InputNumber,
  Form,
  Row,
  message,
  notification,
} from "antd";
import "moment/locale/el";
import ExcelConfigItem from "./ExcelConfigItem";

const { Option } = Select;

const layout = {
  labelCol: { span: { sm: 24, md: 8, lg: 6 } },
  wrapperCol: { span: { sm: 24, md: 16, lg: 12 } },
};

const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
};

class ManageExcelConfig extends React.Component {
  state = {
    users: [],
    selectedUser: null,
    excelConfiguration: null,
    loading: false,
  };

  componentDidMount() {
    this.setState({ loading: true });
    http
      .get("/admin/user")
      .then((res) => {
        this.setState({ users: res.data, loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("error fetchng users");
      });
  }

  saveConfig = () => {
    this.setState({ loading: true });
    http
      .post(
        "/admin/shippingOrder/config/" + this.state.selectedUser,
        this.state.excelConfiguration
      )
      .then((res) => {
        message.success("Επιτυχής αποθήκευση");
      })
      .catch((error) => {
        message.error("Σφάλμα αποθήκευσης");
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  checkRegex = (rgx) => {
    http.post("/admin/checkRegex/", { regex: rgx }).catch((error) => {
      if (error.response) {
        const args = {
          message: "Σφάλμα σύνταξης Regex",
          description: (
            <div>
              <p>
                {" "}
                {JSON.stringify(error.response.data).replace(/\\\\/g, "\\")}
              </p>
              <p>
                Για οδηγίες σύνταξης επισκεφθείτε το:
                <br />
                <a href="https://docs.microsoft.com/en-us/dotnet/standard/base-types/regular-expression-language-quick-reference">
                  Microsoft documentation
                </a>
              </p>
            </div>
          ),
          placement: "bottomRight",
        };

        notification.error(args);
      }
    });
  };

  changeColumn = (columnName, field, value) => {
    console.log(columnName, field, value);
    var index = this.state.excelConfiguration.columns.findIndex(
      (item) => item.columnName === columnName
    );
    //console.log("found index:",index)
    const newConfigObj = { ...this.state.excelConfiguration.columns[index] };
    newConfigObj[field] = value;
    const newExcelConfigColumns = [...this.state.excelConfiguration.columns];
    newExcelConfigColumns.splice(index, 1, newConfigObj);

    const newExcelConfig = {
      ...this.state.excelConfiguration,
      columns: newExcelConfigColumns,
    };
    this.setState({ excelConfiguration: newExcelConfig });
  };

  changeConf = (field, value) => {
    console.log(field, value);
    //console.log("found index:",index)
    const newExcelConfig = { ...this.state.excelConfiguration };
    newExcelConfig[field] = value;
    this.setState({ excelConfiguration: newExcelConfig });
  };

  setSelectedUserId = (userId) => {
    this.setState({ selectedUser: userId, loading: true });

    http
      .get("/admin/shippingOrder/config/" + userId)
      .then((res) => {
        this.setState({ excelConfiguration: res.data });
      })
      .catch((error) => {
        message.error("Σφάλμα λήψης δεδομένων");
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { excelConfiguration, users, selectedUser, loading } = this.state;

    const formItems =
      excelConfiguration &&
      excelConfiguration.columns &&
      excelConfiguration.columns.map((x) => (
        <ExcelConfigItem
          key={x.columnName}
          changeColumn={this.changeColumn}
          column={x}
          defaultDateFormat={this.state.excelConfiguration.defaultDateFormat}
          defaultNumberDecimalPoint={
            this.state.excelConfiguration.defaultNumberDecimalPoint
          }
          checkRegex={this.checkRegex}
        />
      ));

    return (
      <div>
        <Heading>Παραμετροποίηση Excel εντολών μεταφοράς</Heading>
        <Loading loading={loading}>
          <Wrapper>
            <Select
              showSearch
              size="large"
              placeholder="Επιλέξτε Πελάτη"
              optionFilterProp="children"
              onSelect={this.setSelectedUserId}
              allowClear={true}
            >
              {users &&
                users.map((x) => (
                  <Option
                    value={x.userId}
                    key={x.userId}
                    selected={x.userId === selectedUser}
                  >
                    {x.userId}
                  </Option>
                ))}
            </Select>
          </Wrapper>
          {selectedUser && excelConfiguration && (
            <Wrapper>
              {/* span: { sm: 24, md: 8, lg: 6 } */}
              <Form size="small" labelAlign="left" {...formItemLayout}>
                <h3>2. Γενική παραμετροποίηση</h3>
                <Form.Item
                  label="Έναρξη από γραμμή"
                  style={{ maxWidth: "700px" }}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    value={this.state.excelConfiguration.startRow}
                    onChange={(value) => this.changeConf("startRow", value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Γενικός χαρακτήρας υποδιαστολής"
                  style={{ maxWidth: "700px" }}
                >
                  <Input
                    value={
                      this.state.excelConfiguration.defaultNumberDecimalPoint
                    }
                    onChange={(e) =>
                      this.changeConf(
                        "defaultNumberDecimalPoint",
                        e.target.value
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Γενική μορφή ημερομηνιών"
                  style={{ maxWidth: "700px" }}
                >
                  <Input
                    value={this.state.excelConfiguration.defaultDateFormat}
                    onChange={(e) =>
                      this.changeConf("defaultDateFormat", e.target.value)
                    }
                  />
                </Form.Item>

                <h3>3. Παραμετροποίηση στηλών</h3>
                <br />
                <Row gutter={[16, 16]}>{formItems}</Row>
                <Button
                  type="primary"
                  size="large"
                  style={{ float: "right", padding: "0 45px 0 45px" }}
                  onClick={this.saveConfig}
                >
                  Αποθήκευση
                </Button>
              </Form>
            </Wrapper>
          )}
        </Loading>
      </div>
    );
  }
}

export default ManageExcelConfig;
