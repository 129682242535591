const StandardSorter = (a, b) => -((a > b) - (a < b))
const StandardSorterOld = (a, b) => {
    if(a==b){
        return 0;
    }
    if(a<b){
        return -1
    }
    else{
        return 1
    }

}

export { StandardSorter }