import React from "react";
import { Form, Input, Button, Checkbox, Card } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

const LoginForm = (props) => {
  const { loading, onFinish, errorMessage, clearErrorMessage } = props;
  return (
    <Card
      title={<h3 style={{ textAlign: "center" }}>Είσοδος</h3>}
      bordered={true}
    >
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onChange={clearErrorMessage}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Εισάγετε όνομα χρήστη" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Όνομα χρήστη"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Εισάγετε κωδικό πρόσβασης" }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Κωδικός πρόσβασης"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Παραμείνετε συνδεμένοι</Checkbox>
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Είσοδος
          </Button>
        </Form.Item>

        {errorMessage && (
          <div className="ant-form-item-explain ant-form-item-explain-error">
            {" "}
            {errorMessage}
          </div>
        )}
      </Form>
    </Card>
  );
};

export default LoginForm;
