import React from 'react';
import { Upload} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Loading from '../../../Common/Loading'

const { Dragger } = Upload;

function ProcessFile(props ) {

    const {uploadFile}= props;

    const draggerConfig = {
        name: 'file',
        multiple: false,
        customRequest: (options) => {
            uploadFile(options.file);
    },
        showUploadList: false
    };


    return (
        <Dragger {...draggerConfig} style={{marginBottom:"15px"}}>
            <div style={{padding:"7%"}}></div>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Κάντε κλίκ εδώ για να επιλέξετε ένα αρχείο ή σείρετε το αρχείο μέσα σε αυτό το πλαίσιο.</p>
            <p className="ant-upload-hint">
                Μετά την επεξεργασία του αρχείου θα μπορείτε να δείτε αν το αρχείο είναι σωστό ή περιέχει λάθη.<br/>
                Το αρχείο πρέπει να ακολουθεί το πρότυπο εισαγωγής.
            </p>
            <div style={{padding:"7%"}}></div>
        </Dragger>
    )
}

export default ProcessFile;
