export const mapPaymentType = (value) => {
    switch (value) {
      case 0: return "Αντικαταβολή"
      case 1: return "Τράπεζα"
      case 2: return "Επιταγή"
      case 3: return "Μετρητά"
      case 4: return "Paypal"
      case 5: return "Πιστωτική"
      case 6: return "Επί πιστώσει"
      default: return value;
    }
  }
  