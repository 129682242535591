import React from 'react'
import {Spin} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />;

function Heading(props) {
    const {loading, children} = props;
  return (
    <Spin spinning={loading} indicator={antIcon} style={{color: "#ed1a3b"}}> 
    {children}
    </Spin>
  );
}

export default Heading;