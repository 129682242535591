const TOKEN = "token";
const FCOMPANYCODE = "fCompanyCode";

const setToken = (token, remember) => {
  if (token) {
    sessionStorage.setItem(TOKEN, token);
  }
  if (remember && token) {
    localStorage.setItem(TOKEN, token);
  }
};

const getToken = () => {
  let token = sessionStorage.getItem(TOKEN);
  if (token == null) {
    token = localStorage.getItem(TOKEN);
  }
  return token;
};

const setFCompanyCode = (fCompanyCode, remember) => {
  if (fCompanyCode) {
    sessionStorage.setItem(FCOMPANYCODE, fCompanyCode);
  }
  if (remember && fCompanyCode) {
    localStorage.setItem(FCOMPANYCODE, fCompanyCode);
  }
};

const getFCompanyCode = () => {
  let fCompanyCode = sessionStorage.getItem(FCOMPANYCODE);
  if (fCompanyCode == null) {
    fCompanyCode = localStorage.getItem(FCOMPANYCODE);
  }
  return fCompanyCode;
};

const clearToken = () => {
  sessionStorage.removeItem(TOKEN);
  localStorage.removeItem(TOKEN);
};

export { setToken, getToken, clearToken, setFCompanyCode, getFCompanyCode };
