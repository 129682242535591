import axios from 'axios'
import {getToken} from './storage'

const instance = axios.create();

instance.defaults.baseURL = process.env.REACT_APP_BASE_URL // the prefix of the URL
instance.defaults.headers.get['Accept'] = 'application/json'   // default header for all get request
instance.defaults.headers.post['Accept'] = 'application/json'

instance.interceptors.request.use(function (config) {
    let token = getToken();
    if(token){
        config.headers.Authorization =  'Bearer '+token;
    }

    return config;
});

export default instance;