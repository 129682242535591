import React from "react";
import { Progress } from "antd";

class LoadingIndicator extends React.Component {
  mounted = false;
  constructor(props) {
    super(props);
    this.filledCounter = 0;
    this.clearInterval = null;
    this.state = {
      percent: 0,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.clearInterval = setInterval(() => {
      this.filledCounter =
        this.state.percent === 100
          ? this.filledCounter + 2
          : this.filledCounter;
      this.filledCounter = this.filledCounter > 70 ? 0 : this.filledCounter;
      let newpercent = this.state.percent;
      if (this.filledCounter === 0) {
        newpercent = this.state.percent >= 100 ? 0 : this.state.percent + 1;
      }
      if (this.mounted) {
        this.setState({ percent: newpercent });
      }
    }, 20);
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.clearInterval) {
      clearInterval(this.clearInterval);
    }
  }

  render() {
    return (
      <Progress
        percent={this.state.percent}
        showInfo={false}
        status={this.filledCounter > 0 ? "active" : "normal"}
        strokeColor="#ed1a3b"
        style={{ padding: "0 10px" }}
      />
    );
  }
}

export default LoadingIndicator;
