import React from "react";
import { Table, Tooltip, Collapse, Result, Button, List } from "antd";
import "./ShowProcessResults.css";
import { formatDateGr } from "../../../../Utilities/Formatters";
import { StandardSorter } from "../../../../Utilities/Sorters";
import { mapPaymentType } from "../../shippingOrdersUtils";
import {
	WarningFilled,
	CheckOutlined,
	SaveOutlined,
	RollbackOutlined,
	CloseCircleFilled,
} from "@ant-design/icons";

const { Panel } = Collapse;

const renderBarcodeBox = (value, record) => {
	if (!record.isparent && !record.children) return value;

	const childBarcodeBoxesJoined = record.children.map((x, i) => (
		<span key={i}>
			{x.barcodeBox}
			<br />
		</span>
	));

	return (
		<Tooltip placement='right' title={childBarcodeBoxesJoined}>
			{record.children[0].barcodeBox}, ...
		</Tooltip>
	);
};

const columns = (data) =>
	[
		{
			title: "Γραμμή",
			dataIndex: "row",
			render: (row) => row + ".",
		},
		{
			title: "Αριθμός παραστατικού",
			dataIndex: "invoiceNumber",
			render: (value) => (
				<span style={{ whiteSpace: "nowrap" }}>{value}</span>
			),
		},
		{
			title: "Barcode Αποστολής",
			dataIndex: "barcodeVoucher",
			sorter: (a, b) =>
				StandardSorter(a.barcodeVoucher, b.barcodeVoucher),
			hidden: data?.every(({ barcodeVoucher }) => !barcodeVoucher),
		},
		{
			title: "Barcode κιβωτίου",
			dataIndex: "barcodeBox",
			render: renderBarcodeBox,
			sorter: (a, b) => StandardSorter(a.barcodeBox, b.barcodeBox),
			hidden: data?.every(({ barcodeBox }) => !barcodeBox),
		},
		{
			title: "Ημερομηνία παραστατικού",
			dataIndex: "docDate",
			render: (value) => formatDateGr(value),
		},
		{
			title: "Ημερομηνία αποστολής",
			dataIndex: "dispatchDate",
			render: (value) => formatDateGr(value),
		},
		{
			title: "Παραλήπτης",
			dataIndex: "receiverName",
		},
		{
			title: "Οδός",
			dataIndex: "receiverAddress",
		},
		{
			title: "Πόλη",
			dataIndex: "receiverTown",
		},
		{
			title: "Νομός",
			dataIndex: "receiverCounty",
		},
		{
			title: "Τ.Κ.",
			dataIndex: "receiverZipcode",
		},
		{
			title: "Kινητό παραλήπτη",
			dataIndex: "receiverMobile",
		},
		{
			title: "Τηλέφωνο παραλήπτη",
			dataIndex: "receiverTel",
		},
		{
			title: "Ποσότητα",
			dataIndex: "unit",
		},
		{
			title: "Μονάδα μέτρησης",
			dataIndex: "measurementUnit",
		},
		{
			title: "Βάρος",
			dataIndex: "boxWeight",
		},
		{
			title: "Όγκος",
			dataIndex: "boxVolume",
		},
		{
			title: "Ημερομηνία παράδοσης",
			dataIndex: "deliveryDate",
			render: (value) => formatDateGr(value),
		},
		{
			title: "Τρόπος πληρωμής",
			dataIndex: "paymentType",
			render: (value) => mapPaymentType(value),
		},
		{
			title: "Ποσό πληρωμής",
			dataIndex: "paymentAmount",
		},
		{
			title: "Κωδικός πληρωμής",
			dataIndex: "paymentCode",
		},
		{
			title: "Σχόλια",
			dataIndex: "comments",
		},
	].filter((item) => {
		return !(
			(item.dataIndex === "barcodeBox" ||
				item.dataIndex === "barcodeVoucher") &&
			item?.hidden
		);
	});

function ShowProcessResults(props) {
	const { processingResults, reset, saveRecords } = props;

	const successCount = processingResults
		? processingResults.filter((el) => !el.hasErrors).length
		: 0;
	const totalCount = processingResults ? processingResults.length : 0;
	const errorCount = totalCount - successCount;

	const generalError = (
		<Result
			title='Αδυναμία ανάγνωσης αρχείου'
			subTitle='Δοκιμάστε να εισάγετε αρχείο που ακολουθεί το πρότυπο.'
			icon={
				<CloseCircleFilled style={{ fontSize: 40, color: "tomato" }} />
			}
		/>
	);

	const resultProcessed = (
		<React.Fragment>
			{errorCount > 0 && (
				<div style={{ textAlign: "center", fontSize: "1.1em" }}>
					<WarningFilled
						style={{
							fontSize: 30,
							color: "orange",
							margin: "10px",
						}}
					/>
					<br /> Εγγραφές με σφάλματα{" "}
					<b>
						{errorCount}/{totalCount}{" "}
					</b>{" "}
				</div>
			)}
			{errorCount === 0 && (
				<div style={{ textAlign: "center", fontSize: "1.1em" }}>
					<CheckOutlined
						style={{
							fontSize: 30,
							color: "dodgerblue",
							margin: "10px",
						}}
					/>
					<br />{" "}
					<b>
						{successCount}/{totalCount}{" "}
					</b>{" "}
					Εγγραφές αναγνώσθηκαν χωρίς σφάλματα
				</div>
			)}
			<br />
			<Collapse>
				<Panel header={"Προεπισκόπιση  εγγραφών"} key='preview'>
					<Table
						bordered
						size='small'
						columns={columns(processingResults)}
						dataSource={processingResults}
						scroll={{ x: true }}
						rowClassName={(row) =>
							row.hasErrors ? "error-row" : "success-row"
						}
						expandable={{
							expandedRowClassName: (record, index) =>
								record.hasErrors ? "error-row" : "",
							expandedRowRender: (record, index) => (
								<List
									size='small'
									dataSource={
										record.errors ? record.errors : []
									}
									renderItem={(item) => (
										<List.Item
											style={{
												color: "red",
												backgroungColor: "mistyrose",
											}}>
											{item}
										</List.Item>
									)}
								/>
							),
							expandIcon: () => null,
							rowExpandable: (record) => record.hasErrors,
							expandRowByClick: true,
							defaultExpandAllRows: true,
						}}
					/>
				</Panel>
			</Collapse>
		</React.Fragment>
	);

	return (
		<div>
			<div style={{ marginTop: "15px" }}>
				{!!processingResults ? resultProcessed : generalError}
				<div style={{ marginTop: "20px" }}>
					<Button size='large' onClick={reset}>
						<RollbackOutlined />
						Καταχώρηση νέου αρχείου
					</Button>
					{!!processingResults && (
						<Button
							type='primary'
							disabled={errorCount > 0}
							size='large'
							style={{ float: "right" }}
							onClick={saveRecords}>
							<SaveOutlined /> Αποθήκευση
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}

export default ShowProcessResults;
