import React from 'react';
import {  Collapse, Result, Button } from 'antd';

import { SaveOutlined, RollbackOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

function ShowSaveResults(props) {
  const { savedItems, getVoucher, reset } = props;

  const savedCount = !!savedItems ? savedItems.length : 0;

  const success = <Result
    status="success"
    title={"Επιτυχής αποθήκευση " + savedCount + " εγγραφών"}
  />
  const error = <Result
    status="error"
    title={"Αποτυχία αποθήκευσης"}
  />
  return (
    <div>
      <div style={{ marginTop: "20px" }}>
        {savedCount>0?success:error}
        <Button size="large" onClick={reset}><RollbackOutlined />Καταχώρηση νέου αρχείου</Button>
        {savedCount > 0 && 
        <Button type="primary" size="large" style={{ float: "right" }} onClick={getVoucher}>
          <SaveOutlined /> Εκτύπωση voucher
            </Button>
        }
      </div>
    </div>


  )
}

export default ShowSaveResults;