import React from "react";
import "./Login.css";
import LoginForm from "./LoginForm/LoginForm";
import { setToken } from "../Utilities/storage";
import http from "../Utilities/httpWithoutRedirections";
import { withRouter } from "react-router-dom";
import UserContext from "../UserContext";
import Logo from "../Logo";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
    };
    this.history = props.history;
  }

  setCurrentUser = (user, userType, fCompanyCode) => {
    console.log("login here");
    this.context.setCurrentUser(user, userType, fCompanyCode);
  };

  login = (values) => {
    let credentials = {
      username: values.username,
      password: values.password,
    };

    this.setState({ loading: true });

    http
      .post("/auth/login", credentials)
      .then((res) => {
        this.setState({ loading: false });

        setToken(res.data.token, values.remember);

        this.setCurrentUser(
          res.data.userId,
          res.data.userType,
          res.data.fCompanyCode
        );
        this.history.push("/");
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (!error.response) {
          this.setState({ errorMessage: "Αδυναμία σύνδεσης με server" });
          return;
        }

        if (error.response && error.response.status == 401) {
          this.setState({
            errorMessage: "Λάθος κωδικός πρόσβασης ή όνομα χρήστη",
          });
        } else if (error.response && error.response.status == 403) {
          this.history.push("/update-password/first");
        } else {
          this.setState({ errorMessage: "Συνέβει ένα σφάλμα" });
        }
      });
  };

  clearErrorMessage = () => {
    if (this.state.errorMessage) {
      this.setState({ errorMessage: null });
    }
  };

  render() {
    const { errorMessage, loading } = this.state;
    return (
      <div className="login-page">
        <div className="login-column">
          <div className="login-container">
            <LoginForm
              loading={loading}
              onFinish={this.login}
              errorMessage={errorMessage}
              clearErrorMessage={this.clearErrorMessage}
            />
          </div>
        </div>
      </div>
    );
  }
}

Login.contextType = UserContext;

export default withRouter(Login);
